import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";

import { FaExternalLinkAlt } from "@react-icons/all-files/fa/FaExternalLinkAlt";

import { FormattedMessage } from "gatsby-plugin-react-intl";

const ImpactCalculations = () => {
  return (
    <Layout>
      <Seo title="Impact calculations" />
      <div className="w-full bg-white pt-20 flex flex-col items-center pb-20 sm:pb-40">
        <div className="w-full lg:max-w-1080 px-4 mt-20">
          <h1 className="text-3xl md:text-5xl font-bold text-gray-900 mb-4 lg:max-w-2xl">
            <FormattedMessage id="impact_calculations_heading" />
          </h1>
          <p className="text-sm lg:max-w-lg mt-8">
            <FormattedMessage id="impact_calculations_text" />
          </p>
        </div>

        <div className="w-full lg:max-w-1080 px-4 mt-20">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
            <FormattedMessage id="impact_calculations_data" />
          </h2>
          <div className="border-b border-gray-100 py-8">
            <h3 className="text-lg font-medium text-gray-700 pb-2">
              <FormattedMessage id="impact_calculations_grid_emission_intensity" />
            </h3>
            <div className="text-sm py-2 flex justify-between flex-col lg:flex-row">
              <div className="flex flex-col">
                <p className="w-full sm:w-[400px]">
                  <FormattedMessage id="impact_calculations_grid_emission_intensity_text" />
                </p>
                <a
                  href="https://energinet.dk/-/media/675C0B04547A4F75930A130E8346E266.pdf"
                  className="mt-4"
                >
                  <div className="font-light bg-gray-100 py-2 px-2 rounded-md flex hover:bg-gray-200 text-xs w-20">
                    <FormattedMessage id="impact_calculations_source" />{" "}
                    <FaExternalLinkAlt className="mt-[2px] ml-2" />
                  </div>
                </a>
              </div>
              <div className="flex items-start mt-4 lg:mt-0">
                <div className="bg-gray-800 text-white px-2 py-4 rounded-md w-full sm:w-60 text-right">
                  122
                  <span className="ml-1 text-xs text-gray-200">
                    kg CO2e / MWh
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-100 py-8">
            <h3 className="text-lg font-medium text-gray-700 pb-2">
              <FormattedMessage id="impact_calculations_solar_emission_intensity" />
            </h3>
            <div className="text-sm py-2 flex justify-between flex-col lg:flex-row">
              <div className="flex flex-col">
                <p className="w-full sm:w-[400px]">
                  <FormattedMessage id="impact_calculations_solar_emission_intensity_text" />
                </p>
                <a
                  href="https://www.carbonbrief.org/solar-wind-nuclear-amazingly-low-carbon-footprints#"
                  className="mt-4"
                >
                  <div className="font-light bg-gray-100 py-2 px-2 rounded-md flex hover:bg-gray-200 text-xs w-20">
                    <FormattedMessage id="impact_calculations_source" />{" "}
                    <FaExternalLinkAlt className="mt-[2px] ml-2" />
                  </div>
                </a>
              </div>
              <div className="flex items-start mt-4 lg:mt-0">
                <div className="bg-gray-800 text-white px-2 py-4 rounded-md w-full sm:w-60 text-right">
                  6
                  <span className="ml-1 text-xs text-gray-200">
                    kg CO2e / MWh
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="py-8">
            <h3 className="text-lg font-medium text-gray-700 pb-2">
              <FormattedMessage id="impact_calculations_citizen_consumption" />
            </h3>
            <div className="text-sm py-2 flex justify-between flex-col lg:flex-row">
              <div className="flex flex-col">
                <p className="w-full sm:w-[400px]">
                  <FormattedMessage id="impact_calculations_citizen_consumption_text" />
                </p>
                <a
                  href="https://data.worldbank.org/indicator/EN.ATM.CO2E.PC?locations=DK"
                  className="mt-4"
                >
                  <div className="font-light bg-gray-100 py-2 px-2 rounded-md flex hover:bg-gray-200 text-xs w-20">
                    <FormattedMessage id="impact_calculations_source" />{" "}
                    <FaExternalLinkAlt className="mt-[2px] ml-2" />
                  </div>
                </a>
              </div>
              <div className="flex items-start mt-4 lg:mt-0">
                <div className="bg-gray-800 text-white px-2 py-4 rounded-md w-full sm:w-60 text-right">
                  5.8
                  <span className="ml-1 text-xs text-gray-200">
                    t CO2e / <FormattedMessage id="impact_calculations_year" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:max-w-1080 px-4">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mt-16 mb-4">
            <FormattedMessage id="impact_calculations_method" />
          </h2>
          <div className="border-b border-gray-100 py-8">
            <h3 className="text-lg font-medium text-gray-700 pb-2">
              <FormattedMessage id="impact_calculations_emissions_grid" />
            </h3>
            <div className="text-sm py-2 flex justify-between flex-col lg:flex-row">
              <div className="flex flex-col">
                <p className="w-full sm:w-[400px]">
                  <FormattedMessage id="impact_calculations_emissions_grid_text" />
                </p>
              </div>
              <div className="flex items-start mt-4 lg:mt-0">
                <div className="bg-gray-800 text-white px-2 py-4 rounded-md w-120 sm:text-right leading-8">
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_grid_emissions_calc_1" />
                  </span>
                  =
                  <br className="sm:hidden" />
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_grid_emissions_calc_2" />
                  </span>
                  ×
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_grid_emissions_calc_3" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-100 py-8">
            <h3 className="text-lg font-medium text-gray-700 pb-2">
              <FormattedMessage id="impact_calculations_emissions_solar" />
            </h3>
            <div className="text-sm py-2 flex justify-between flex-col lg:flex-row">
              <div className="flex flex-col">
                <p className="w-full sm:w-[400px]">
                  <FormattedMessage id="impact_calculations_emissions_solar_text" />
                </p>
              </div>
              <div className="flex items-start mt-4 lg:mt-0">
                <div className="bg-gray-800 text-white px-2 py-4 rounded-md w-120 sm:text-right leading-8">
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_solar_emissions_calc_1" />
                  </span>
                  =
                  <br className="sm:hidden" />
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_solar_emissions_calc_2" />
                  </span>
                  ×
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_solar_emissions_calc_3" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-gray-100 py-8">
            <h3 className="text-lg font-medium text-gray-700 pb-2">
              <FormattedMessage id="impact_calculations_reduction" />
            </h3>
            <div className="text-sm py-2 flex justify-between flex-col lg:flex-row">
              <div className="flex flex-col">
                <p className="w-full sm:w-[400px]">
                  <FormattedMessage id="impact_calculations_reduction_text" />
                </p>
              </div>
              <div className="flex items-start mt-4 lg:mt-0">
                <div className="bg-gray-800 text-white px-2 py-4 rounded-md w-120 sm:text-right leading-8">
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_reduction_calc_1" />
                  </span>
                  =
                  <br className="" />
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_reduction_calc_2" />
                  </span>
                  −
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_reduction_calc_3" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="py-8">
            <h3 className="text-lg font-medium text-gray-700 pb-2">
              <FormattedMessage id="impact_calculations_citizen_eq" />
            </h3>
            <div className="text-sm py-2 flex justify-between flex-col lg:flex-row">
              <div className="flex flex-col">
                <p className="w-full sm:w-[400px]">
                  <FormattedMessage id="impact_calculations_citizen_eq_text" />
                </p>
              </div>
              <div className="flex items-start mt-4 lg:mt-0">
                <div className="bg-gray-800 text-white px-2 py-4 rounded-md w-120 sm:text-right leading-8">
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_citizen_eq_calc_1" />
                  </span>
                  =
                  <br className="" />
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_citizen_eq_calc_2" />
                  </span>
                  /
                  <span className="bg-[#40496B] rounded-sm px-1 py-1 mx-1">
                    <FormattedMessage id="impact_calculations_citizen_eq_calc_3" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ImpactCalculations;
